<template>
  <div class="codePage">
    <div class="title">输入税局验证码</div>
    <div class="tips">
      验证码已发送至法人 {{ telephone }} {{ name }}，用于授权系统为
      企业匹配产品，为安全起见，请勿泄露
    </div>
    <van-password-input :value="smsCode" :mask="false" gutter="18px" :focused="showKeyboard"
      @focus="showKeyboard = true" />
    <van-number-keyboard v-model="smsCode" :show="showKeyboard" @blur="showKeyboard = false" />
    <div class="count" v-if="count != 60">{{ count }}s后重新获取验证码</div>
    <div class="count" v-else @click="reGetCode">重新获取验证码</div>
    <div class="footer">
      <div class="fanhui" @click="back">返回</div>
      <div class="queding" @click="nextPage">确定</div>
    </div>
  </div>
</template>
<script>
  import url from "@/service/url-config";
  export default {
    data() {
      return {
        smsCode: "",
        showKeyboard: false,
        count: 60,
        name: this.$route.query.name || "",
        telephone: this.$route.query.telephone || "",
        timer: ''
      };
    },
    watch: {
      smsCode(value) {
        if (value.length != 6) {
          this.errorInfo = "密码错误";
        } else {
          this.errorInfo = "";
        }
      },
    },
    mounted() {
      this.jiShi();
    },
    methods: {
      //60s⏲
      jiShi() {
        this.count = 59;
        const timer = setInterval(() => {
          let time = this.count;
          this.count = time - 1;
          if (this.count < 1) {
            clearInterval(timer);
            this.count = 60;
          }
        }, 1000);
      },
      reGetCode() {
        this.jiShi();
        this.sendCode();
      },
      sendCode() {
        const {
          codeType,
          // employeeId,
          origin,
          taxAccount,
          taxPassword,
        } = this.$route.query;
        const data = {
          codeType,
          // employeeId,
          origin,
          taxAccount,
          taxPassword,
        };
        this.$http.post(url.getLoginCode, data).then((res) => {
          if (res.code == 200) {
            const {
              name,
              telephone
            } = res.data;
            this.$router.replace({
              name: "code",
              params: {
                name,
                telephone,
                ...data,
              },
              replace: true
            });
          }
        });
      },
      back() {
        this.$router.back(-1);
      },
      nextPage() {
        this.smsCode;
        if (this.smsCode && this.smsCode.length == 6) {
          const {
            codeType,
            employeeId,
            origin,
            taxAccount,
            taxPassword,
          } = this.$route.query;
          if (this.timer) clearTimeout(this.timer)
          this.timer = setTimeout(() => {
            this.$http.post(url.shuiwu, {
              codeType,
              employeeId,
              origin,
              taxAccount,
              taxPassword,
              smsCode: this.smsCode,
            }).then(res => {
              if (res.code == 200) {
                const {
                  companyId,
                  loanPredictionId
                } = res.data
                this.$router.replace({
                  name: 'match',
                  query: {
                    companyId,
                    loanPredictionId,
                    taxAccount
                  },
                  replace: true
                })
              } else {
                // this.$toast(res.msg)
              }
            })
          }, 500);

        } else {
          this.$toast("请输入六位验证码");
        }
      },
    },
    beforeDestroy() {
      if (this.timer) clearTimeout(this.timer)
    }
  };
</script>
<style lang="less" scoped>
  .codePage {
    background: rgba(0, 132, 255, 1);
    height: 100vh;
    width: 100%;

    .title {
      padding: 46px 35px 0;
      text-align: left;
      color: #ffffff;
      font-weight: bold;
      font-size: 28px;
      margin-bottom: 20px;
    }

    .tips {
      padding: 0 35px 40px;
      text-align: left;
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
    }

    .van-password-input__security li {
      background: rgba(0, 132, 255, 1);
      border-bottom: 2px solid rgba(255, 255, 255, 0.3);
      font-size: 28px;
      color: #ffffff;
    }

    .count {
      margin-top: 30px;
      padding: 0 20px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffff00;
      line-height: 17px;
    }

    .footer {
      margin-top: 90px;
      display: flex;
      justify-content: space-around;

      .fanhui,
      .queding {
        width: 140px;
        text-align: center;
        line-height: 40px;
        background: rgba(242, 242, 242, 0.19);
        border-radius: 2px;
        color: rgba(255, 255, 255, 0.7);
        font-size: 15px;
      }
    }
  }
</style>